import React from 'react';
import { C62, weightOptions } from 'constants/weightOptions';
import { getClickContext } from 'utils/clickContext';
import {
  Argentina,
  Armenia,
  Australia,
  Austria,
  AwardWinning,
  British,
  Canada,
  California,
  Chile,
  England,
  France,
  Georgia,
  Germany,
  Greece,
  Hungary,
  India,
  Israel,
  Italy,
  Information,
  Jersey,
  Lebanon,
  Macedonia,
  Moldova,
  NewZealand,
  Portugal,
  Romania,
  Slovenia,
  SouthAfrica,
  Spain,
  Turkey,
  UnitedStates,
  Wales,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { GTM_TRACKED_CONFLICTS, AVAILABLE, UNAVAILABLE } from 'constants/conflicts';

export function toDSPodUom(uomId) {
  const uom = weightOptions[uomId];
  return uom ? { ...uom, id: uom.unit } : undefined;
}

export function toDSPodUoms(uoms) {
  const weightUoms = uoms.filter(unit => unit !== C62);
  const weight = weightUoms.length > 0 ? toDSPodUom(weightUoms[0]) : undefined;
  const item = uoms.includes(C62) ? toDSPodUom(C62) : undefined;

  return item || weight
    ? {
        ...(item ? { item } : {}),
        ...(weight ? { weight } : {}),
      }
    : { item: toDSPodUom(C62) };
}

export const updateTrolleyParams = (quantity, event, ctx, quantityKey = 'quantity') => ({
  [quantityKey]: { amount: quantity.amount, uom: quantity.uomId },
  lineNumber: ctx.lineNumber,
  productId: ctx.productId,
  productPosition: ctx.position,
  clickContext: ctx.clickContext || getClickContext(event),
});

export function makePodDataset({
  lineNumber,
  hasPromotions,
  name,
  productType,
  productPosition,
  conflictMessage,
  podType,
}) {
  let availability = AVAILABLE;

  if (conflictMessage) {
    availability = GTM_TRACKED_CONFLICTS.includes(conflictMessage) ? conflictMessage : UNAVAILABLE;
  }

  return {
    'data-product-availability': availability,
    'data-product-id': lineNumber,
    'data-product-name': name,
    'data-product-type': productType,
    'data-product-on-offer': hasPromotions,
    'data-product-pod-type': podType,
    'data-product-index': productPosition,
  };
}

const badgeObjectConverter = {
  'Award winner': { type: 'green' },
  'Best in season': { type: 'green' },
  Christmas: { type: 'seasonal' },
  'Coronation edition': { type: 'limitedEdition' },
  'Everyday value': { type: 'valueGreen' },
  'Meal deal': { type: 'offer' },
  New: { type: 'green' },
  'New lower price': { type: 'valueGreen' },
  'Online offer': { type: 'offer' },
  'Online only offer': { type: 'offer' },
  'Special buy': { type: 'offer' },
  'Wine event': { type: 'wineEvent' },
};

// any tag not appearing on the list will be displayed with the default chip color (grey)
const tagObjectConverter = {
  'Allergen update': { type: 'red' },
  'Award winning': { type: 'award', icon: <AwardWinning /> },
  BBQ: { type: 'greenAlternative' },
  'd+': { type: 'greenAlternative' },
  'Fresher for longer': { type: 'greenAlternative' },
  Frozen: { type: 'blue' },
  Freezable: { type: 'blue' },
  'Fruit & Vanilla': { type: 'yellow' },
  'Fruits & Honey': { type: 'greenAlternative' },
  'Long life': { type: 'greenAlternative' },
  'In season': { type: 'greenAlternative' },
  'New Recipe': { type: 'red' },
  'Slower reared': { type: 'green', icon: <British /> },
  'Spice & Caramel': { type: 'red' },
  // COUNTRIES LIST
  Argentina: { type: 'blue', icon: <Argentina /> },
  Armenia: { type: 'yellow', icon: <Armenia /> },
  Australia: { type: 'blue', icon: <Australia /> },
  Austria: { type: 'red', icon: <Austria /> },
  British: { type: 'blue', icon: <British /> },
  California: { type: 'red', icon: <California /> },
  Canada: { type: 'red', icon: <Canada /> },
  Chile: { type: 'blue', icon: <Chile /> },
  England: { type: 'red', icon: <England /> },
  France: { type: 'blue', icon: <France /> },
  Georgia: { type: 'red', icon: <Georgia /> },
  Germany: { type: 'yellow', icon: <Germany /> },
  Greece: { type: 'blue', icon: <Greece /> },
  Hungary: { type: 'green', icon: <Hungary /> },
  India: { type: 'green', icon: <India /> },
  Israel: { type: 'blue', icon: <Israel /> },
  Italy: { type: 'green', icon: <Italy /> },
  Jersey: { type: 'red', icon: <Jersey /> },
  Lebanon: { type: 'green', icon: <Lebanon /> },
  Macedonia: { type: 'yellow', icon: <Macedonia /> },
  Moldova: { type: 'yellow', icon: <Moldova /> },
  'New Zealand': { type: 'blue', icon: <NewZealand /> },
  Portugal: { type: 'green', icon: <Portugal /> },
  Romania: { type: 'yellow', icon: <Romania /> },
  Slovenia: { type: 'blue', icon: <Slovenia /> },
  'South Africa': { type: 'green', icon: <SouthAfrica /> },
  Spain: { type: 'yellow', icon: <Spain /> },
  Turkey: { type: 'red', icon: <Turkey /> },
  USA: { type: 'blue', icon: <UnitedStates /> },
  Wales: { type: 'green', icon: <Wales /> },
};

export const mapDataToBadges = productData => {
  if (!productData?.marketingBadges?.length) return null;

  return productData.marketingBadges.map(badge =>
    badgeObjectConverter[badge?.name] ? { ...badge, ...badgeObjectConverter[badge?.name] } : badge,
  );
};

export const mapDataToTags = productData => {
  if (!productData?.productTags?.length) return null;

  return productData.productTags.map(tag => {
    if (tag.name.includes('d+')) return { name: tag.name, ...tagObjectConverter['d+'] };
    return tagObjectConverter[tag?.name]
      ? {
          ...tag,
          ...tagObjectConverter[tag?.name],
          ...(tag.tooltip ? { trailingIcon: <Information /> } : {}),
        }
      : tag;
  });
};
